import React from "react";
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import loadable from "@loadable/component";
import Layout from "../components/layout";
const Banner = loadable(() => import("../components/Banner/Banner"));
const ReviewSlider = loadable(() => import("../components/ReviewSlider/ReviewSlider"));

const Testimonials = ({ data }, props) => {
    const PageData = data?.strapiPage
    const elfData = data?.site.siteMetadata?.elfSight
    const allAgentData = data?.site.siteMetadata?.allAgent
    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>
            {PageData.banner && <Banner title={PageData.banner?.banner_title} {...PageData.banner} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms} bannerClass="inner-banner"/>}
            {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.video-review" && <ReviewSlider  reviews={module.add} id={PageData.strapi_id} /> }
                    </>
                )
            })}
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default Testimonials


export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
              ...GlobalModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_VIDEO_REVIEW {
              ...VideoReviewFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
              ...PlainContentFragment
            }
        }
    }

    site {
        siteMetadata {
            elfSight {
              review
              review_badge
              review_carousel
              review_page
            }
            allAgent {
              review
              id
            }
        }
      }
  }
`